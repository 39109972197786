<template>
    <v-row justify="center">
        <v-card class="ma-6">
            <v-card-title class="text-h2 text">
                Frequently Asked Questions
            </v-card-title>
            <v-divider></v-divider>

        </v-card>
        <v-expansion-panels inset focusable>
            <v-expansion-panel
                v-for="chapter in chapters"
                :key="chapter.id"
            >
                <v-expansion-panel-header>{{ $t(chapter.title) }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul>
                        <li v-for="content of chapter.contents" :key="content.id">
                            <question-card>
                                <template #question>
                                    {{ $t(content.question) }}
                                </template>
                                <template #answer>
                                    {{ $t(content.answer) }}
                                </template>
                            </question-card>
                        </li>
                    </ul>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</template>

<script>
import QuestionCard from "@/components/Help/QuestionCard";

export default {
    name: "FAQ",
    components: {
        QuestionCard
    },
    data() {
        return {
            chapters: [
                {
                    id: 1,
                    title: 'faq.main',
                    contents: [
                        {
                            id: 1,
                            question: 'faq.main1q',
                            answer: 'faq.main1a'
                        },
                    ]
                },
                {
                    id: 2,
                    title: 'faq.map',
                    contents: [
                        {
                            id: 1,
                            question: 'faq.map1q',
                            answer: 'faq.map1a'
                        },
                        {
                            id: 2,
                            question: 'faq.map2q',
                            answer: 'faq.map2a'
                        },
                        {
                            id: 3,
                            question: 'faq.map3q',
                            answer: 'faq.map3a'
                        },

                    ]
                },
                {
                    id: 3,
                    title: 'faq.communities',
                    contents: [
                        {
                            id: 1,
                            question: 'faq.communities1q',
                            answer: 'faq.communities1a'
                        },
                        {
                            id: 2,
                            question: 'faq.communities2q',
                            answer: 'faq.communities2a'
                        },
                    ]
                },
                {
                    id: 4,
                    title: 'faq.zones',
                    contents: [
                        {
                            id: 1,
                            question: 'faq.zones1q',
                            answer: 'faq.zones1a'
                        },
                        {
                            id: 2,
                            question: 'faq.zones2q',
                            answer: 'faq.zones2a'
                        },
                    ]
                },
                {
                    id: 5,
                    title: 'faq.nodes',
                    contents: [
                        {
                            id: 1,
                            question: 'faq.nodes1q',
                            answer: 'faq.nodes1a'
                        },
                        {
                            id: 2,
                            question: 'faq.nodes2q',
                            answer: 'faq.nodes2a'
                        },
                        {
                            id: 3,
                            question: 'faq.nodes3q',
                            answer: 'faq.nodes3a'
                        },
                        {
                            id: 4,
                            question: 'faq.nodes4q',
                            answer: 'faq.nodes4a'
                        },
                    ]
                },
                {
                    id: 6,
                    title: 'faq.schedules',
                    contents: [
                        {
                            id: 1,
                            question: 'faq.schedules1q',
                            answer: 'faq.schedules1a'
                        },
                        {
                            id: 2,
                            question: 'faq.schedules2q',
                            answer: 'faq.schedules2a'
                        }
                    ]
                },
            ]
        };
    },
}
</script>