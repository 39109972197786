<template>
    <v-card >
        <v-card-title background-color="green">
            <slot name="question">
                How can I move the map?
            </slot>
        </v-card-title>
        <v-card-text>
            <slot name="answer">
                Keep the mouse-button pressed and move the mouse..
            </slot>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "QuestionCard"
}
</script>

<style scoped>

</style>