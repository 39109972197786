<template>
    <v-container class="d-flex justify-center">
        <v-card class="my-10" outlined elevation="3" color="lightgrey" width="1000">
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-card-title>Login</v-card-title>
                    <v-form ref="form" @submit.prevent="submit">
                        <v-text-field
                            color="red"
                            v-model="username"
                            label="Username"
                            name="username"
                            placeholder="username"
                            required
                        ></v-text-field>
                        <v-text-field
                            color="red"
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            label="Password"
                            name="password"
                            plcaeholder="password"
                            required
                            @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-card-actions>
                            <v-btn
                            text
                            color="accent"
                            type="submit"
                             class="bottom-right"
                            :disabled="!isValid"
                            data-cy="login-btn"
                            >
                            Login
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                    <div v-if="showError && username === ''">
                        <v-card color="warning" class="ma-4 pa-4">
                            <p>{{ $t(errorMessage) }}</p>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: 'Login',
    data () {
        return {
            username: '',
            password: '',
            showError: false,
            show1: false,
            loggedIn: false,
            isLoading: false,
            errorMessage: '',
        };
    },
    computed: {
        ...mapGetters({'authStatus': 'auth/authStatus'}),
        loading() {
            return this.authStatus === 'loading';
        },
        isValid(){
            return this.username.length > 0 && this.password.length > 0;
        },
    },
    methods: {
        async submit() {
            const {username, password} = this;
            const authResult = await this.$store.dispatch("auth/AuthRequest", {username, password});
            if (authResult.status === 201){
                // user is logged in correctly
                this.errorMessage = "";
                this.$router.go(-1); // go back to page we came from.
            } else {
                if (this.authStatus === "incorrect credentials") {
                    this.errorMessage = 'errors.usernamePasswordIncorrect';
                    this.showError = true;
                }
            }
            // empty everything
            this.username = '';
            this.password = '';
            this.loggedIn = false;
        },
    },
};
</script>
<style scoped>
.error-message {
    color: red;
}
</style>